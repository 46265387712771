import React from 'react';
import {MdExpandMore, MdSync, MdDone, MdWarning} from "react-icons/md";
import {ButtonRaw} from "../Form";
import {createDropDown} from "../DropDown";
import {withTranslation} from "../../lib/i18n";
import {withProjectActions} from "./StoreProjectActions";
import {LoadingRequest} from "../Loading";
import {ProjectActionHistory} from "./ProjectActionHistory";

const DropDown = createDropDown('auto', 0);

class ProjectsListEnvBase extends React.Component {
    state = {
        envInfoOpen: false,
        envActionsOpen: false
    };

    componentDidMount() {
        this.props.loadProjectActions(this.props.project);
    }

    render() {
        const {
            t,
            requestProjectActions
        } = this.props;

        const {envs} = this.props.projectActions[this.props.project] || {};

        return <div className={'projects-list--project--env border-around'}>
            <h3>{this.props.id}</h3>
            <ButtonRaw onClick={() => this.setState({envActionsOpen: !this.state.envActionsOpen})}
                       active={this.state.envActionsOpen}
                       style={{display: 'flex', padding: '4px 0',}}
            >
                <span style={{display: 'flex', margin: '0 0 0 6px'}}>
                    {t('env-actions')}&nbsp;
                </span>
                {requestProjectActions && requestProjectActions[this.props.project] && true !== requestProjectActions[this.props.project] ?
                    <LoadingRequest request={requestProjectActions[this.props.project]} styleWrapper={{display: 'inline-block', margin: 'auto'}}/> : null}
                <span style={{marginLeft: requestProjectActions && requestProjectActions[this.props.project] && true === requestProjectActions[this.props.project] ? 'auto' : 0, display: 'flex', alignItems: 'center'}}>
                    {envs && envs[this.props.id] && (envs[this.props.id].finished || envs[this.props.id].unfinished) ?
                        <React.Fragment>
                            <strong>({envs[this.props.id].finished + envs[this.props.id].unfinished})</strong>&nbsp;
                            {envs[this.props.id].last_finished < envs[this.props.id].last_unfinished ?
                                <MdSync size={'1em'} style={{display: 'inline-block'}}/> :
                                envs[this.props.id].error ?
                                    <MdWarning size={'1em'} style={{display: 'inline-block'}}/> :
                                    <MdDone size={'1em'} style={{display: 'inline-block'}}/>}
                        </React.Fragment>
                        : <strong style={{marginRight: 2}}>(0)</strong>}
                    <MdExpandMore size={'1.35em'} style={{display: 'inline-block', transform: this.state.envActionsOpen ? 'rotate(-180deg)' : '', transition: '0.4s linear transform'}}/>
                </span>
            </ButtonRaw>
            <DropDown open={this.state.envActionsOpen} className={'bg-light'} style={{display: 'flex', flexDirection: 'column', flexShrink: 0, flexGrow: 0, maxHeight: '300px', overflow: 'auto'}}>
                <ProjectActionHistory project={this.props.project} onlyEnv={this.props.id}/>
            </DropDown>
            <ButtonRaw onClick={() => this.setState({envInfoOpen: !this.state.envInfoOpen})}
                       active={this.state.envInfoOpen}
                       style={{display: 'flex', padding: '4px 0',}}
            >
                <span style={{display: 'flex', alignItems: 'center', margin: '0 0 0 6px'}}>
                {t('env-settings')}
                </span>
                <MdExpandMore size={'1.35em'}
                              style={{display: 'inline-block', marginLeft: 'auto', transform: this.state.envInfoOpen ? 'rotate(-180deg)' : '', transition: '0.4s linear transform'}}/>
            </ButtonRaw>
            <DropDown open={this.state.envInfoOpen} className={'bg-light'} style={{display: 'flex', flexDirection: 'column', flexShrink: 0, flexGrow: 0}}>
                <table style={{margin: '0 auto', width: 'auto'}}>
                    <tbody>
                    <tr>
                        <td>live</td>
                        <td>{this.props.env.live}</td>
                    </tr>
                    <tr>
                        <td>live_after</td>
                        <td>{this.props.env.live_after}</td>
                    </tr>
                    <tr>
                        <td>zip</td>
                        <td>{this.props.env.zip}</td>
                    </tr>
                    <tr>
                        <td>zip_folder</td>
                        <td>{this.props.env.zip_folder}</td>
                    </tr>
                    <tr>
                        <td>tmp</td>
                        <td>{this.props.env.tmp}</td>
                    </tr>
                    <tr>
                        <td>skeleton</td>
                        <td>{this.props.env.skeleton}</td>
                    </tr>
                    <tr>
                        <td>beforeSwitch</td>
                        <td>{this.props.env.beforeSwitch ? 'yes' : 'no'}</td>
                    </tr>
                    <tr>
                        <td>afterSwitch</td>
                        <td>{this.props.env.afterSwitch ? 'yes' : 'no'}</td>
                    </tr>
                    </tbody>
                </table>
            </DropDown>
        </div>;
    }
}

const ProjectsListEnv = withTranslation('projects')(withProjectActions(ProjectsListEnvBase));
export {ProjectsListEnv};