import React from 'react';
import {MdSync, MdDone, MdWarning, MdLaunch} from "react-icons/md";
import {withProjects} from "./StoreProjects";
import {themed} from "../../lib/theme";
import {LoadingRequest} from "../Loading";
import './ProjectsList.scss';
import {ProjectsListEnv} from "./ProjectsListEnv";
import {withProjectActions} from "./StoreProjectActions";
import {withTranslation} from "react-i18next";
import {ButtonRaw} from "../Form";
import {Link} from "react-router-dom";

class ProjectsListBase extends React.Component {
    intervalId = undefined;

    componentDidMount() {
        this.forceLoad();

        this.intervalId = setInterval(() => {
            if(true === this.props.requestProjects) {
                // only load again if loaded already
                this.forceLoad();
            }
        }, 2500);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    forceLoad = () => {
        this.props.loadProjects(true);
        const {requestProjectActions} = this.props;
        if(requestProjectActions) {
            Object.keys(requestProjectActions).forEach(project => {
                if(requestProjectActions[project] && true === requestProjectActions[project]) {
                    // only load again if loaded already
                    this.props.loadProjectActions(project, true);
                }
            });
        }
    };

    render() {
        const {
            t, i18n,
            projects
        } = this.props;

        return (
            <div className={'projects-list'} style={{display: 'flex', overflow: 'auto', flexDirection: 'column'}}>
                <h1 className={'bg-dark'} style={{display: 'flex'}}>{t('title')}
                    <ButtonRaw
                        onClick={() => {
                            this.props.loadProjects(true);
                            if(this.props.projectActions) {
                                Object.keys(this.props.projectActions).forEach(project => this.props.loadProjectActions(project, true));
                            }
                        }}
                        style={{verticalAlign: 'bottom', margin: 'auto 0 auto auto', padding: 2}}
                    ><MdSync style={{display: 'block'}}/></ButtonRaw></h1>
                <div style={{display: 'flex', overflow: 'auto', flexDirection: 'column', position: 'relative'}}>
                    {true !== this.props.requestProjects ?
                        <div style={{marginTop: projects ? 5 : 30, position: projects ? 'absolute' : 'relative', transform: 'translateX(-50%)', left: '50%'}}>
                            <LoadingRequest request={this.props.requestProjects}/>
                        </div>
                        : null}
                    {projects ?
                        Object.keys(projects).map((project) => (
                            <div className={'projects-list--project border-bottom'} key={project}>
                                <h2 style={{fontWeight: 'bolder'}}>{projects[project].id}</h2>

                                {this.props.projectActions[project] && this.props.projectActions[project].actions && this.props.projectActions[project].actions && this.props.projectActions[project].actions[0] ?
                                    <div style={{fontFamily: 'monospace'}}>
                                        <p style={{margin: '2px 0'}}>{t('last-action')}:</p>
                                        <p style={{margin: '2px 0', fontStyle: 'italic'}}>{t('last-action-on')} {this.props.projectActions[project].actions[0].env}</p>

                                        <Link className={'text'} to={'/' + i18n.languages[0] + '/projects/' + project + '/' + this.props.projectActions[project].actions[0].env + '/' + this.props.projectActions[project].actions[0].ID} style={{padding: 4}}>
                                            {this.props.projectActions[project].actions[0].time}&nbsp;
                                            {this.props.projectActions[project].actions[0].action}&nbsp;
                                            {this.props.projectActions[project].actions[0].finished ?
                                                this.props.projectActions[project].actions[0].error ?
                                                    <MdWarning size={'1.35em'} style={{verticalAlign: 'bottom'}}/> :
                                                    <MdDone size={'1.35em'} style={{verticalAlign: 'bottom'}}/> :
                                                <MdSync size={'1.35em'} style={{verticalAlign: 'bottom'}}/>}&nbsp;
                                            <MdLaunch size={'1.35em'} style={{verticalAlign: 'bottom'}}/>
                                        </Link>
                                    </div>
                                    : null}

                                <div className={'projects-list--project--envs'}>
                                    {projects[project].envs ?
                                        Object.keys(projects[project].envs).map(env => (
                                            <ProjectsListEnv env={projects[project].envs[env]} id={env} project={project} key={env}/>
                                        ))
                                        : <p>no-envs</p>}
                                </div>
                            </div>
                        )) : null}
                </div>
            </div>
        );
    }
}

const ProjectsList = withTranslation('projects')(themed(withProjects(withProjectActions(ProjectsListBase))));

export {ProjectsList};