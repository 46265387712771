import React from 'react';

const ThemeContext = React.createContext({});

class ThemeProviderBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: window.localStorage.getItem('automat_theme') || 'dark'
        };

        this.state.toggleTheme = () => {
            if(this.state.theme === 'light') {
                window.localStorage.setItem('automat_theme', 'dark');
                this.setState({theme: 'dark'});
            } else {
                window.localStorage.setItem('automat_theme', 'light');
                this.setState({theme: 'light'});
            }
        };
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <ThemeContext.Provider value={this.state}>
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}

const themed = (Component) => (
    (props) => {
        return (
            <ThemeContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </ThemeContext.Consumer>
        )
    }
);

const ThemeProvider = ThemeProviderBase;

export {ThemeProvider, themed};