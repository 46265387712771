import React from 'react';

const KeyboardShortcutsContext = React.createContext({});

class KeyboardShortcutsProviderBase extends React.Component {
    constructor(props) {
        super(props);

        this.shortcuts = {
            ctrl: {
                '77': 'menu'
            }
        };

        this.state = {
            shortcutsInUse: {},
            shortcutsPressed: {},
            isShortActive: this.isShortActive,
            shortWasPressed: this.shortWasPressed,
            setShort: this.setShort
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    shortWasPressed = (id) => {
        if(this.state.shortcutsPressed.hasOwnProperty(id)) {
            return this.state.shortcutsPressed[id];
        }

        return false;
    };

    isShortActive = (id) => {
        if(this.state.shortcutsInUse.hasOwnProperty(id)) {
            return this.state.shortcutsInUse[id];
        }

        return false;
    };

    setShort = (id, val) => {
        const shorts = {...this.state.shortcutsInUse};
        shorts[id] = val;
        this.setState({
            shortcutsInUse: shorts,
        });
    };

    handleKeyDown = (evt) => {
        evt = evt || window.event;
        if(evt.ctrlKey && !evt.altKey && !evt.shiftKey) {
            if(this.shortcuts.ctrl.hasOwnProperty(evt.keyCode)) {
                evt.preventDefault();
                const shortToToggle = this.shortcuts.ctrl[evt.keyCode];
                const shorts = {...this.state.shortcutsInUse};
                if(shorts.hasOwnProperty(shortToToggle)) {
                    shorts[shortToToggle] = !shorts[shortToToggle];
                } else {
                    shorts[shortToToggle] = true;
                }
                const shortsHistory = {...this.state.shortcutsPressed};
                shortsHistory[shortToToggle] = true;
                this.setState({
                    shortcutsInUse: shorts,
                    shortcutsPressed: shortsHistory
                });
            }
        }
    };

    render() {
        return (
            <KeyboardShortcutsContext.Provider value={this.state}>
                {this.props.children}
            </KeyboardShortcutsContext.Provider>
        );
    }
}

const withKeyboardShortcuts = (Component) => (
    (props) => {
        return (
            <KeyboardShortcutsContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </KeyboardShortcutsContext.Consumer>
        )
    }
);

const KeyboardShortcutsProvider = KeyboardShortcutsProviderBase;

export {KeyboardShortcutsProvider, withKeyboardShortcuts};