import React from 'react';
import {sortString} from '@formanta/js';
import {apiRequest, GET} from "../../lib/api";

const ProjectActionsStoreContext = React.createContext({});

class ProjectActionsStoreProviderBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectActions: {},
            projectActionsEnv: {},
            projectActionsDetail: {},
            requestProjectActions: {},
            requestProjectActionsDetail: {},
            loadProjectActions: this.loadProjectActions,
            loadProjectActionDetail: this.loadProjectActionDetail,
        };
    }

    componentDidUpdate(prevProps) {
    }

    updateRequest(project, state) {
        const requestProjectActions = {...this.state.requestProjectActions};
        requestProjectActions[project] = state;
        // eslint-disable-next-line
        this.state.requestProjectActions[project] = state;
        this.setState({requestProjectActions});
    }

    loadProjectActions = (project, force = false) => {

        if((!this.state.projectActions.hasOwnProperty(project) && (this.state.requestProjectActions && !this.state.requestProjectActions[project])) || force === true) {
            this.updateRequest(project, 'progress');

            apiRequest('api/projects/actions/' + project, GET)
                .then(res => {
                    if(res.data) {
                        this.updateRequest(project, true);

                        const isFinished = (id) => {
                            for(let i = 0; i < res.data.length; i++) {

                                if(res.data[i].start === id && res.data[i].state === 'end-action') {
                                    return i;
                                }
                            }
                            return false;
                        };

                        const envs = {};
                        const actions = [];
                        res.data.forEach(elem => {
                            if(elem.env && elem.state === 'new-action') {
                                if(!envs[elem.env]) {
                                    envs[elem.env] = {
                                        finished: 0,
                                        unfinished: 0,
                                        error: false,
                                        inprogress: false,
                                        last_finished: '0',
                                        last_unfinished: '0'
                                    };
                                }
                                const index = isFinished(elem.ID);
                                if(false !== index) {
                                    elem.finished = true;
                                    envs[elem.env].finished++;
                                    // eslint-disable-next-line
                                    elem.error = (res.data[index].error == 0 ? false : true);
                                    if(elem.time > envs[elem.env].last_finished) {
                                        envs[elem.env].last_finished = elem.time;
                                        envs[elem.env].error = elem.error;
                                    }
                                    actions.push(elem);
                                } else {
                                    elem.finished = false;
                                    envs[elem.env].unfinished++;
                                    elem.error = false;
                                    if(elem.time > envs[elem.env].last_unfinished) {
                                        envs[elem.env].last_unfinished = elem.time;
                                        envs[elem.env].error = elem.error;
                                    }
                                    actions.push(elem);
                                }
                            }
                        });

                        const projectActions = {...this.state.projectActions};
                        projectActions[project] = {
                            envs,
                            actions: sortString(actions, 'time').reverse()
                        };
                        this.setState({
                            projectActions,
                        });
                    } else {
                        this.updateRequest(project, 'error');
                    }

                    return res;
                })
                .catch(res => {
                    this.updateRequest(project, 'error');
                    return Promise.reject(res);
                });
        }
    };

    updateRequestActionDetail(start_id, state) {
        const requestProjectActionsDetail = {...this.state.requestProjectActionsDetail};
        requestProjectActionsDetail[start_id] = state;
        // eslint-disable-next-line
        this.state.requestProjectActionsDetail[start_id] = state;
        this.setState({requestProjectActionsDetail});
    }

    loadProjectActionDetail = (start_id, force = false) => {

        if((!this.state.projectActionsDetail.hasOwnProperty(start_id) && (this.state.requestProjectActionsDetail && !this.state.requestProjectActionsDetail[start_id])) || force === true) {
            this.updateRequestActionDetail(start_id, 'progress');

            apiRequest('api/projects/action/' + start_id, GET)
                .then(res => {
                    if(res.data) {
                        const projectActionsDetail = {...this.state.projectActionsDetail};
                        projectActionsDetail[start_id] = sortString(res.data, 'time');
                        this.setState({
                            projectActionsDetail,
                        }, () => this.updateRequestActionDetail(start_id, true));
                    } else {
                        this.updateRequestActionDetail(start_id, 'error');
                    }

                    return res;
                })
                .catch(res => {
                    this.updateRequestActionDetail(start_id, 'error');
                    return Promise.reject(res);
                });
        }
    };

    render() {
        return (
            <ProjectActionsStoreContext.Provider value={this.state}>
                {this.props.children}
            </ProjectActionsStoreContext.Provider>
        );
    }
}

const withProjectActions = (Component) => (
    (props) => {
        return (
            <ProjectActionsStoreContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </ProjectActionsStoreContext.Consumer>
        )
    }
);

const ProjectActionsStoreProvider = ProjectActionsStoreProviderBase;

export {ProjectActionsStoreProvider, withProjectActions};