import React from 'react';
import {withProjects} from "../Projects/StoreProjects";
import {withDeployments} from "../Deployments/StoreDeployments";
import {themed} from "../../lib/theme";
import {LoadingRequest} from "../Loading";
import {withTranslation} from "react-i18next";
import {DeploymentsProjects, DeploymentsTitle, DeploymentZoneElem} from "./Deployments";

class DeploymentDetailModulBase extends React.Component {
    intervalId = undefined;

    componentDidMount() {
        this.props.loadProjects();
        this.loadDeployments();
        this.intervalId = setInterval(() => {
            if(true === this.props.getRequestDepForZone(this.props.deployment)) {
                // only load again if loaded already
                // todo: and not finished
                this.loadDeployments(false, true);
            }
        }, 2500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadDeployments(prevProps, true);
    }

    loadDeployments = (prevProps = false, forceload = false) => {
        const deployment = this.props.deployment;
        if(!prevProps) {
            if(deployment) {
                if(!prevProps.match || (this.props.deployment !== prevProps.deployment)) {
                    this.props.loadDeploymentForZone(deployment, forceload);
                }
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        const deployment = this.props.deployment;

        return (
            deployment ?
                <React.Fragment>
                    {true !== this.props.getRequestDepForZone(deployment) ?
                        <div style={{position: this.props.getDepForZone(deployment) ? 'absolute' : 'relative', transform: 'translateX(-50%)', left: '50%'}}>
                            <LoadingRequest request={this.props.getRequestDepForZone(deployment)}/>
                        </div>
                        : null}

                    {this.props.getDepForZone(deployment) ?
                        <DeploymentZoneElem
                            open={true}
                            deployment={this.props.getDepForZone(deployment)}
                            servers={[this.props.getDepForZone(deployment)]}
                        /> :
                        null}
                </React.Fragment>
                : null
        );
    }
}

const DeploymentDetailModul = withTranslation('deployments')(themed(withProjects(withDeployments(DeploymentDetailModulBase))));

class DeploymentDetailBase extends React.Component {
    render() {
        const deployment = this.props.match && this.props.match.params.deployment ? this.props.match.params.deployment : false;

        return (
            <DeploymentsTitle plural={false} suffix={'Details'} onClick={() => {
                this.props.loadProjects(true);
                if(deployment) {
                    this.props.loadDeploymentForZone(deployment, true);
                }
            }}>
                <div style={{display: 'flex', overflow: 'auto', position: 'relative', flexGrow: 2}}>

                    {true === this.props.getRequestDepForZone(deployment) && this.props.getDepForZone(deployment) ?
                        <DeploymentsProjects project={this.props.getDepForZone(deployment).project} env={this.props.getDepForZone(deployment).env}/> :
                        <DeploymentsProjects/>}

                    {deployment ?
                        <div className={'content-container'} style={{flexGrow: 2, position: 'relative', overflow: 'auto'}}>
                            <DeploymentDetailModul deployment={deployment}/>
                        </div> : null}
                </div>
            </DeploymentsTitle>
        );
    }
}

const DeploymentDetail = withTranslation('deployments')(themed(withProjects(withDeployments(DeploymentDetailBase))));

export {DeploymentDetail, DeploymentDetailModul};