import React from 'react';
import {MdLeakRemove, MdMoreHoriz} from "react-icons/md";
import {ButtonRaw} from "../component/Form";
import {themed} from "../lib/theme";
import {withTranslation} from "react-i18next";
import {withUserAuth} from "../component/User/StoreAuth";
import {Link} from "react-router-dom";

class HeaderBase extends React.Component {
    render() {
        const {
            i18n,
            openSidebar, sidebarOpen
        } = this.props;

        return (
            <div className={'header bg-dark'}>
                {this.props.authServer && this.props.authKey ?
                    <Link to={'/' + i18n.languages[0] + '/projects'} className='btn btn-raw' style={{background: 'transparent', color: 'inherit', margin: 'auto 0', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}><MdLeakRemove size={'1.6em'}/> Automat</Link> :
                    <p style={{margin: 'auto 0', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}><MdLeakRemove size={'1.6em'}/> Automat</p>}
                <ButtonRaw onClick={openSidebar} style={{marginLeft: 'auto', padding: 2}} active={sidebarOpen}>
                    <MdMoreHoriz size={'2em'} style={{display: 'block'}}/>
                </ButtonRaw>
            </div>
        );
    }
}

const Header = themed(withTranslation('common')(withUserAuth(HeaderBase)));

export {Header};