import React from 'react';
import {NavLink as Link} from 'react-router-dom';
import {MdInvertColors, MdTranslate, MdList, MdDns, MdSettings, MdClear, MdMoreHoriz, MdWork, MdPermContactCalendar} from "react-icons/md";
import {themed} from "../lib/theme";
import {withTranslation} from '../lib/i18n';
import {ButtonRaw} from "../component/Form";
import posed from 'react-pose';
import {withUserAuth} from "../component/User/StoreAuth";

const Wrapper = posed.div({
    hidden: {
        width: 0,
    },
    visible: {
        width: 'auto',
    }
});

const WrapperDrop = posed.div({
    hidden: {
        height: 0,
    },
    visible: {
        height: 'auto',
    }
});

class SidebarBase extends React.Component {
    state = {
        openLangSwitch: false
    };

    toggleOnMobileNav = () => {
        if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 920) {
            this.props.openSidebar();
        }
    };

    render() {
        const {
            toggleTheme, open,
            t, i18n,
            match, history,
            doLogout,
            openSidebar
        } = this.props;

        /**
         * Change the current language, get the current url and url scheme, search for key `:lng` in pathscheme and use that index to manipulate history
         * @param lng
         */
        const changeLanguage = (lng) => {
            let cur_path = history.location.pathname.substr(1).split('/');
            let path_pos = match.path.substr(1).split('/').indexOf(':lng');

            cur_path[path_pos] = lng;
            i18n.changeLanguage(lng);
            history.push('/' + cur_path.join('/'));
        };

        const iconStyle = {size: '1.45em', style: {margin: 3}};

        return (
            <Wrapper className={'sidebar'} withParent={false} pose={open ? 'visible' : 'hidden'}>
                <div style={{margin: '12px 20px 0 20px', display: 'flex', overflowX: 'visible', flexShrink: 0}}>
                    <ul style={{display:'flex',flexDirection:'column'}}>
                        {this.props.authServer && this.props.authKey ?
                            <React.Fragment>
                                <li><Link to={'/' + i18n.languages[0] + '/projects'} className='btn btn-raw' onClick={this.toggleOnMobileNav}><MdList {...iconStyle}/>{t('sidebar.projects')}</Link></li>
                                <li><Link to={'/' + i18n.languages[0] + '/artifacts'} className='btn btn-raw' onClick={this.toggleOnMobileNav}><MdWork {...iconStyle}/>{t('sidebar.artifacts')}</Link></li>
                                <li><Link to={'/' + i18n.languages[0] + '/deployments'} className='btn btn-raw' onClick={this.toggleOnMobileNav}><MdDns {...iconStyle}/>{t('sidebar.deployments')}</Link></li>
                                <li><Link to={'/' + i18n.languages[0] + '/settings'} className='btn btn-raw' onClick={this.toggleOnMobileNav}><MdSettings {...iconStyle}/>{t('sidebar.settings')}</Link></li>
                            </React.Fragment> :
                            <li><Link to={'/' + i18n.languages[0] + '/auth'} className='btn btn-raw'><MdPermContactCalendar {...iconStyle}/>{t('sidebar.login')}</Link></li>}
                        <li>
                            <ButtonRaw onClick={toggleTheme}><MdInvertColors {...iconStyle}/> {t('sidebar.switch-theme')}</ButtonRaw>
                        </li>
                        <li>
                            <ButtonRaw onClick={() => this.setState({openLangSwitch: !this.state.openLangSwitch})}
                                       active={this.state.openLangSwitch}
                            ><MdTranslate {...iconStyle}/> {t('sidebar.switch-lang')}</ButtonRaw>
                            <WrapperDrop withParent={false} pose={this.state.openLangSwitch ? 'visible' : 'hidden'} style={{overflow: 'hidden'}}>
                                <ul>
                                    <li><ButtonRaw onClick={() => changeLanguage('en')}>en</ButtonRaw></li>
                                    <li><ButtonRaw onClick={() => changeLanguage('de')}>de</ButtonRaw></li>
                                </ul>
                            </WrapperDrop>
                        </li>
                        {this.props.authServer && this.props.authKey ?
                            <li><Link to={'/' + i18n.languages[0] + '/auth'} onClick={() => {
                                doLogout();
                                this.toggleOnMobileNav();
                            }} className='btn btn-raw'><MdClear {...iconStyle}/>{t('sidebar.logout')}</Link></li> : null}
                    </ul>
                </div>

                <ButtonRaw onClick={openSidebar} style={{marginLeft: 'auto', position: 'absolute', bottom: 10}} className={'sidebar-toggle-left'} active={open}>
                    <MdMoreHoriz size={'2em'} style={{display: 'block', padding: 6}}/>
                </ButtonRaw>
            </Wrapper>
        );
    }
}

const Sidebar = themed(withTranslation('common')(withUserAuth(SidebarBase)));

export {Sidebar};