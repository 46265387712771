import React from 'react';
import {withProjects} from "../Projects/StoreProjects";
import {withDeployments} from "../Deployments/StoreDeployments";
import {themed} from "../../lib/theme";
import {LoadingRequest} from "../Loading";
import {withTranslation} from "react-i18next";
import {DeploymentsProjects, DeploymentsTitle, DeploymentZoneElem} from "./Deployments";
import './DeploymentsList.scss';

class ProjectsListBase extends React.Component {
    intervalId = undefined;

    componentDidMount() {
        this.props.loadProjects();
        this.loadDeployments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadDeployments(prevProps, true);
    }

    loadDeployments = (prevProps = false, forceload = false) => {
        if(!prevProps || (this.props.match && this.props.match.params)) {
            if(this.props.match.params.project && this.props.match.params.env) {
                if(
                    !prevProps.match ||
                    this.props.match.params.project !== prevProps.match.params.project ||
                    this.props.match.params.env !== prevProps.match.params.env
                ) {
                    this.props.loadDeployments(this.props.match.params.project, this.props.match.params.env, forceload);
                }
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        const {
            match
        } = this.props;

        return (
            <DeploymentsTitle onClick={() => {
                this.props.loadProjects(true);
                this.loadDeployments(false, true)
            }}>
                <div style={{display: 'flex', overflow: 'auto', position: 'relative', flexGrow: 2}}>
                    <DeploymentsProjects showChoose={!match.params.project || !match.params.env}/>

                    {match.params.project && match.params.env ?
                        <div className={'content-container'} style={{flexGrow: 2, position: 'relative', overflow: 'auto'}}>
                            {true !== this.props.getRequestDeployments(match.params.project, match.params.env) ?
                                <div style={{position: 'relative', transform: 'translateX(-50%)', left: '50%'}}>
                                    <LoadingRequest request={this.props.getRequestDeployments(match.params.project, match.params.env)}/>
                                </div>
                                : null}

                            {
                                true === this.props.getRequestDeployments(match.params.project, match.params.env) ? (
                                        this.props.getDeployments(match.params.project, match.params.env) &&
                                        Object.keys(this.props.getDeployments(match.params.project, match.params.env)).length ?
                                            Object.keys(this.props.getDeployments(match.params.project, match.params.env)).map(deployment => (
                                                <DeploymentZoneElem
                                                    key={deployment}
                                                    deployment={deployment}
                                                    servers={this.props.getDeployments(match.params.project, match.params.env)[deployment]}
                                                />
                                            )) :
                                            'no deployment happend so far'
                                    ) :
                                    null}
                        </div>
                        : null}
                </div>
            </DeploymentsTitle>
        );
    }
}

const DeploymentsList = withTranslation('deployments')(themed(withProjects(withDeployments(ProjectsListBase))));

export {DeploymentsList};