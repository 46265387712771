import React from 'react';
import {apiRequest, GET} from "../../lib/api";
import {sortString} from "@formanta/js";

const DeploymentsStoreContext = React.createContext({});

class DeploymentsStoreProviderBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deployments: {},
            depForZone: {},
            requestDeployments: {},
            requestDepForZone: {},
            loadDeployments: this.loadDeployments,
            loadDeploymentForZone: this.loadDeploymentForZone,
            getRequestDeployments: this.getRequestDeployments,
            getRequestDepForZone: this.getRequestDepForZone,
            getDeployments: this.getDeployments,
            getDepForZone: this.getDepForZone,
        };
    }

    componentDidUpdate(prevProps) {
    }

    updateRequest(project, env, state) {
        const requestDeployments = {...this.state.requestDeployments};
        requestDeployments[project + ':' + env] = state;
        // eslint-disable-next-line
        this.state.requestDeployments[project + ':' + env] = state;
        this.setState({requestDeployments});
    }

    getRequestDeployments = (project, env) => {
        if(this.state.requestDeployments.hasOwnProperty(project + ':' + env)) {
            return this.state.requestDeployments[project + ':' + env];
        } else {
            return false;
        }
    };

    getDeployments = (project, env) => {
        if(this.state.deployments.hasOwnProperty(project + ':' + env)) {
            return this.state.deployments[project + ':' + env];
        } else {
            return false;
        }
    };

    loadDeployments = (project, env, force = false) => {
        if(false === this.getRequestDeployments(project, env) || force === true) {
            this.updateRequest(project, env, 'progress');

            apiRequest('api/deployments/project/' + project + '/' + env, GET)
                .then(res => {
                    if(res.data) {
                        this.updateRequest(project, env, true);

                        const deploys = {};
                        res.data = sortString(res.data, 'last_change').reverse();
                        res.data.forEach(dep => {
                            if(!deploys.hasOwnProperty(dep.deployment)) {
                                deploys['D' + dep.deployment] = [];
                            }
                            deploys['D' + dep.deployment].push(dep);
                        });

                        const deployments = {...this.state.deployments};
                        deployments[project + ':' + env] = deploys;
                        this.setState({deployments});
                    } else {
                        this.updateRequest(project, env, 'error');
                    }

                    return res;
                })
                .catch(res => {
                    this.updateRequest(project, env, 'error');
                    return Promise.reject(res);
                });
        }
    };

    updateRequestDepForZone(deployment, state) {
        const requestDepForZone = {...this.state.requestDepForZone};
        requestDepForZone[deployment] = state;
        // eslint-disable-next-line
        this.state.requestDepForZone[deployment] = state;
        this.setState({requestDepForZone});
    }

    getRequestDepForZone = (deployment) => {
        if(this.state.requestDepForZone.hasOwnProperty(deployment)) {
            return this.state.requestDepForZone[deployment];
        } else {
            return false;
        }
    };

    getDepForZone = (deployment) => {
        if(this.state.depForZone.hasOwnProperty(deployment)) {
            return this.state.depForZone[deployment];
        } else {
            return false;
        }
    };

    loadDeploymentForZone = (deployment, force = false) => {
        if(false === this.getRequestDepForZone(deployment) || force === true) {
            this.updateRequestDepForZone(deployment, 'progress');

            apiRequest('api/deployments/track/' + deployment, GET)
                .then(res => {
                    if(res.data && res.data[0]) {
                        this.updateRequestDepForZone(deployment, true);

                        const depForZone = {...this.state.depForZone};
                        depForZone[deployment] = res.data[0];
                        this.setState({depForZone});
                    } else {
                        this.updateRequestDepForZone(deployment, 'error');
                    }

                    return res;
                })
                .catch(res => {
                    this.updateRequestDepForZone(deployment, 'error');
                    return Promise.reject(res);
                });
        }
    };

    render() {
        return (
            <DeploymentsStoreContext.Provider value={this.state}>
                {this.props.children}
            </DeploymentsStoreContext.Provider>
        );
    }
}

const withDeployments = (Component) => (
    (props) => {
        return (
            <DeploymentsStoreContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </DeploymentsStoreContext.Consumer>
        )
    }
);

const StoreDeployments = DeploymentsStoreProviderBase;

export {StoreDeployments, withDeployments};