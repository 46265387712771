import React from 'react';
import {MdSync, MdDone, MdLaunch, MdWarning} from "react-icons/md";
import {Link} from 'react-router-dom';
import {withTranslation} from "../../lib/i18n";
import {withProjectActions} from "./StoreProjectActions";

const styleElement = {
    padding: '6px 4px'
};

class ProjectActionHistoryBase extends React.Component {
    render() {
        const {
            i18n,
            onlyEnv, project
        } = this.props;

        const {actions} = this.props.projectActions[project] || {};

        return actions ?
            <div className={'project-action-history bg-row'} style={{display: 'flex', flexDirection: 'column'}}>
                {actions.map(elem => (
                    !onlyEnv || elem.env === onlyEnv ?
                        elem.state === 'new-action' ?
                            <Link to={'/' + i18n.languages[0] + '/projects/' + elem.project + '/' + elem.env + '/' + elem.ID}
                                  className={'text'}
                                  key={elem.ID}
                                  style={{display: 'flex', textDecoration: 'none'}}>
                                <div style={styleElement}>{elem.time}</div>
                                <div style={styleElement}>{elem.action}</div>
                                <div style={Object.assign({marginLeft: 'auto'}, styleElement)}>{
                                    elem.finished ?
                                        elem.error ?
                                            <MdWarning size={'1.35em'} style={{verticalAlign: 'bottom'}}/> :
                                            <MdDone size={'1.35em'} style={{verticalAlign: 'bottom'}}/> :
                                        <MdSync size={'1.35em'} style={{verticalAlign: 'bottom'}}/>
                                }</div>
                                <div style={styleElement}><MdLaunch size={'1.35em'} style={{verticalAlign: 'bottom'}}/></div>
                            </Link>
                            : null
                        : null
                ))}
            </div>
            : null
    }
}

const ProjectActionHistory = withTranslation('projects')(withProjectActions(ProjectActionHistoryBase));
export {ProjectActionHistory};