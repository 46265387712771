import React from 'react';

class Button extends React.Component {
    state = {};

    render() {
        return (
            <div>

            </div>
        )
    }
}

function ButtonRaw(props) {
    return (
        <button onClick={props.onClick}
                className={'btn-raw' + (props.active ? ' active' : '') + (props.className ? ' ' + props.className : '')}
                style={props.style || {}}
                type={props.type}
        >{props.children}</button>
    )
}

export {Button, ButtonRaw};