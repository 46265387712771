import React from 'react';
import {MdSync, MdExpandMore, MdDone, MdWarning} from "react-icons/md";
import {LoadingRequest} from "../Loading";
import {withTranslation} from "react-i18next";
import {ButtonRaw} from "../Form";
import {NavLink as Link} from "react-router-dom";
import {createDropDown, createDropRight} from "../DropDown";
import {withProjects} from "../Projects/StoreProjects";

const DropDown = createDropDown('auto', '0');
const DropRight = createDropRight('auto', '0');

class DeploymentsServerStageBase extends React.Component {
    render() {
        const {
            t, stage, response,
        } = this.props;

        return (
            <li key={stage} style={{marginBottom: 12}}>
                <div style={{display: 'flex'}}>
                    {response.hasOwnProperty('time') && response.time ?
                        <span className={'text-light'} style={{marginRight: '6px', flexShrink: 0}}>{response.time}</span> : ''}
                    {t('stage.' + stage)}
                </div>

                {response.response && response.response.message ?
                    <div className={'bg-dark'} style={{margin: '4px 0 12px 0'}}>
                        {response.response.message.map((line, i) => (
                            <p key={i} className={'bg-light'} style={{wordBreak: 'break-all', margin: '2px 0 2px -20px', padding: '4px'}}>{line}</p>
                        ))}
                    </div>
                    : ''}
            </li>
        );
    }
}

const DeploymentsServerStage = withTranslation('deployments')(DeploymentsServerStageBase);


class DeploymentZoneElemBase extends React.Component {
    state = {
        openServers: false
    };

    render() {
        const {servers, deployment, i18n, open} = this.props;

        const stages_done = deployment && deployment.stages_done ? deployment.stages_done : false;

        const deployable = servers[0].deployable ? servers[0].deployable : false;

        return (
            servers ?
                servers.length ?
                    <React.Fragment>
                        <ButtonRaw onClick={() => this.setState({openServers: !this.state.openServers})} style={{width: '100%', margin: '2px 0 2px 0', padding: '6px 0 6px 0', display: 'flex', textAlign: 'left'}}>
                            {servers[0] ?
                                <span>
                                    <span>{servers[0].deployment_error * 1 ? <MdWarning/> : servers[0].stage === 'end' ? <MdDone/> : <MdSync/>}&nbsp;</span>
                                    <span>{servers[0].zone}&nbsp;</span>
                                    <span>{servers[0].deployment}&nbsp;</span>
                                </span>
                                : 'Deployment'}

                            {!open ? <MdExpandMore
                                style={{transform: this.state.openServers ? 'rotate(-180deg)' : '', transition: '.25s linear transform', marginLeft: 'auto'}}/> : null}</ButtonRaw>
                        <DropDown open={this.state.openServers || open}>
                            <div style={{textAlign: 'left'}}>
                                {servers[0] ?
                                    <React.Fragment>
                                        <p style={{textAlign: 'left', margin: '4px 0 4px 0', fontFamily: 'monospace'}}>
                                            <span>{servers[0].deployment_time}{servers[0].deployment_time !== servers[0].last_change ? ' > ' + servers[0].last_change + ' ' : ' '}</span>
                                        </p>
                                        <p style={{textAlign: 'left', margin: '4px 0', fontFamily: 'monospace'}}>
                                            <span>{servers[0].project}:{servers[0].env}&nbsp;</span>
                                        </p>
                                        <p style={{textAlign: 'left', margin: '4px 0', fontFamily: 'monospace'}}>
                                            <Link
                                                className={'btn btn-raw'}
                                                style={{display: 'block'}}
                                                to={'/' + i18n.languages[0] + '/projects/' + servers[0].project + '/' + servers[0].env + '/' + servers[0].step_start_id}
                                            >Started from Pipeline Step {servers[0].step_start_id}</Link>
                                        </p>
                                    </React.Fragment>
                                    : null}
                                {deployable ?
                                    <Link className={'btn btn-raw'}
                                          style={{display: 'block', fontFamily: 'monospace'}}
                                          to={'/' + i18n.languages[0] + '/artifacts/deployable/' + deployable.id}
                                    >Deployable: {deployable.name}</Link> :
                                    <span style={{display: 'block', fontFamily: 'monospace'}}>no-deployable</span>}
                                {servers.map(server => (
                                    <ul key={server.id} className={'bg-dark'} style={{padding: '8px 6px', listStyleType: 'none'}}>
                                        <li>
                                            {stages_done ?
                                                <ul style={{paddingLeft: 20}}>{stages_done.map((stage) => (
                                                    <DeploymentsServerStage
                                                        key={stage}
                                                        stage={stage}
                                                        response={
                                                            deployment.payload.hasOwnProperty(stage) && deployment.payload[stage] ?
                                                                deployment.payload[stage] :
                                                                {}}
                                                    />
                                                ))}</ul> : null}
                                            {/*<span>{deployment.id}</span>*/}
                                            <span>{server.error * 1 ? <MdWarning/> : server.stage === 'end' ? <MdDone/> : <MdSync/>}&nbsp;</span>
                                            <span>{server.last_change}&nbsp;</span>
                                            {/*<span>{deployment.payload}</span>*/}
                                            {/*<span>{deployment.server}</span>*/}
                                            <span>{server.stage}&nbsp;</span>
                                            <span>{server.step_start_id}&nbsp;</span>
                                            <span>{server.zone}&nbsp;</span>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        </DropDown>
                    </React.Fragment> : 'no-servers'
                : 'empty-deployment'
        );
    }
}

const DeploymentZoneElem = withTranslation('deployments')(DeploymentZoneElemBase);


class DeploymentsProjectsBase extends React.Component {
    state = {
        open: false
    };

    render() {
        const {
            t, i18n,
            project: customProject, env: customEnv,
            open: openProp,
            projects, showChoose
        } = this.props;

        const open = this.state.open || openProp;

        return (
            <div className={'bg-dark'} style={{textAlign: 'left', display: 'flex', height: '100%', minWidth: '2.65rem', overflow: 'auto', position: 'relative', flexDirection: 'column', padding: '0 6px', flexShrink: 0}}>
                {true !== this.props.requestProjects ?
                    <div style={{position: projects ? 'absolute' : 'relative', transform: 'translateX(-50%)', left: '50%'}}>
                        <LoadingRequest request={this.props.requestProjects}/>
                    </div>
                    : null}
                {projects ?
                    <div style={{marginTop: 26}}>
                        <ButtonRaw onClick={() => this.setState({open: !this.state.open})}
                                   active={open}
                                   style={{
                                       transform: (open ? '' : 'rotate(-90deg) translate(-50%,-100%)') + ' ',
                                       position: 'absolute',
                                       left: open ? 0 : 12,
                                       top: open ? 0 : -18,
                                       transition: '.3s linear transform',
                                       fontWeight: 'bold',
                                       fontSize: '1.125rem',
                                       padding: 4,
                                   }}
                        >Projects</ButtonRaw>
                        <DropRight open={open}>
                            {showChoose ?
                                <p style={{margin: '2px 0', fontStyle: 'italic'}}>{t('choose-env')}</p> : null}
                            {Object.keys(projects).map((project) => (
                                <div key={project} style={{margin: '6px 0 24px 0'}}>
                                    <h2 style={{fontWeight: 'bolder', margin: '0 0 6px 0'}}>{projects[project].id}</h2>

                                    <ul style={{padding: '0 0 0 24px', margin: 0}}>
                                        {projects[project].envs ?
                                            Object.keys(projects[project].envs).map(env => (
                                                <li style={{margin: '2px 0 6px 2px', wordBreak: 'keep-all'}} key={env}>
                                                    <Link className={'btn btn-raw' + (project === customProject && env === customEnv ? ' active' : '')} style={{padding: '2px', display: 'block'}}
                                                          to={'/' + i18n.languages[0] + '/deployments/' + project + '/' + env}>{env}</Link>
                                                </li>
                                            ))
                                            : <li>no-envs</li>}
                                    </ul>
                                </div>
                            ))}
                        </DropRight>
                    </div>
                    : null}
            </div>
        );
    }
}

const DeploymentsProjects = withTranslation('deployments')(withProjects(DeploymentsProjectsBase));

class DeploymentsTitleBase extends React.Component {
    render() {
        const {
            t, onClick, children, suffix,
        } = this.props;

        return (
            <div className={'projects-list'} style={{display: 'flex', overflow: 'auto', flexDirection: 'column'}}>
                <h1 className={'bg-dark'} style={{display: 'flex'}}>{t('title')}{suffix ? ' ' + suffix : null}
                    <ButtonRaw
                        onClick={onClick}
                        style={{verticalAlign: 'bottom', margin: 'auto 0 auto auto', padding: 2}}
                    ><MdSync style={{display: 'block'}}/></ButtonRaw>
                </h1>
                {children}
            </div>
        );
    }
}

const DeploymentsTitle = withTranslation('deployments')(DeploymentsTitleBase);


export {DeploymentsProjects, DeploymentsTitle, DeploymentZoneElem};