import React from 'react';
import {apiRequest, GET} from "../../lib/api";

const ProjectsStoreContext = React.createContext({});

class ProjectsStoreProviderBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: false,
            requestProjects: false,
            loadProjects: this.loadProjects,
        };
    }

    componentDidUpdate(prevProps) {
    }

    loadProjects = (force = false) => {
        if(false === this.state.projects || force === true) {
            this.setState({
                requestProjects: 'progress',
                //projects: false
            });

            apiRequest('api/projects', GET)
                .then(res => {
                    if(res.data && res.data.projects) {
                        this.setState({
                            requestProjects: true,
                            projects: res.data.projects
                        });
                    } else {
                        this.setState({requestProjects: 'error'});
                    }

                    return res;
                })
                .catch(res => {
                    this.setState({requestProjects: 'error'});
                    return Promise.reject(res);
                });
        }
    };

    render() {
        return (
            <ProjectsStoreContext.Provider value={this.state}>
                {this.props.children}
            </ProjectsStoreContext.Provider>
        );
    }
}

const withProjects = (Component) => (
    (props) => {
        return (
            <ProjectsStoreContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </ProjectsStoreContext.Consumer>
        )
    }
);

const ProjectsStoreProvider = ProjectsStoreProviderBase;

export {ProjectsStoreProvider, withProjects};