import React from 'react';
import {MdDeleteSweep, MdCheck} from "react-icons/md";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "../lib/i18n";
import {withUserAuth} from "./User/StoreAuth";
import {ButtonRaw} from "./Form";

class SettingsBase extends React.Component {
    evts = [];

    state = {
        clientCacheCleared: false
    };

    componentWillUnmount() {
        this.evts.forEach((id) => {
            clearTimeout(id);
        });
    }

    clearClientCache = () => {
        let tmp_jwt = window.localStorage.getItem('automat_auth');
        window.localStorage.clear();
        window.localStorage.setItem('automat_auth', tmp_jwt);
        this.setState({
            clientCacheCleared: true
        });

        let id = setTimeout(() => {
            this.setState({
                clientCacheCleared: false
            })
        }, 800);
        this.evts.push(id);

        window.location.reload(true);
    };

    render() {
        const {t, authServer} = this.props;

        return <div style={{width: '100%'}}>
            <h1>{t('settings.title')}</h1>
            <div className={'content-container'} style={{textAlign: 'left'}}>
                <p><strong>{t('settings.server')}:</strong> {authServer}</p>
                <div style={{display: 'flex'}}>
                    <ButtonRaw onClick={this.clearClientCache} style={{padding: 4, marginLeft: -4}}>
                        <strong style={{marginRight: 4, verticalAlign: 'baseline'}}>{t('settings.clear-cache')}</strong>
                        <MdDeleteSweep size={'1.25em'} style={{display: 'inline-block', verticalAlign: 'middle'}}/>
                    </ButtonRaw>
                    {this.state.clientCacheCleared ? <p><MdCheck size={'1.25em'}/></p> : null}
                </div>
                <div style={{marginTop: 30}}>
                    <h2>{t('settings.keyboard-shorts')}</h2>
                    <table>
                        <tbody>
                        <tr>
                            <td><code className={'bg-dark'}>CTRL</code> + <code className={'bg-dark'}>M</code></td>
                            <td style={{paddingLeft: 4}}>{t('settings.keyboard-short.menu')}</td>
                        </tr>
                        </tbody>
                    </table>
                    {this.state.clientCacheCleared ? <p><MdCheck size={'1.25em'}/></p> : null}
                </div>
            </div>
        </div>
    }
}

const Settings = withRouter(withTranslation('common')(withUserAuth(SettingsBase)));
export {Settings};