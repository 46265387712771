import React from 'react';
import {withProjects} from "./StoreProjects";
import {MdKeyboardCapslock, MdDns, MdGetApp, MdArrowDropDownCircle, MdCheckCircle, MdHourglassEmpty, MdSpeakerNotes, MdSpeakerNotesOff, MdSync, MdCancel} from "react-icons/md";
import {themed} from "../../lib/theme";
import {Loading, LoadingRequest} from "../Loading";
import './ProjectsList.scss';
import {withProjectActions} from "./StoreProjectActions";
import {withTranslation} from "react-i18next";
import './ProjectsList.scss';
import {ButtonRaw} from "../Form";
import {createDropDown} from "../DropDown";
import {Link, withRouter} from "react-router-dom";
import {DeploymentDetailModul} from "../Deployments/DeploymentDetail";

const iconSize = '2em';
const iconStyle = {margin: '0 0.25rem'};

const DropDown = createDropDown('auto', 0);

const getDuration = (start, end) => {
    let duration = {};
    duration.seconds = (new Date(end) - new Date(start)) / (1000);
    duration.minutes = Math.floor(duration.seconds / 60);
    return duration;
};

const StatusPipe = (props) => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
        {props.children}
        <div style={{width: '3px', minHeight: props.minHeight ? '6px' : 0, height: props.noPipe ? 0 : 'auto', flexGrow: props.noPipe ? 0 : 3, margin: '0 auto'}} className={'bg-dark'}/>
    </div>
);

class PipelineEventBase extends React.Component {
    render() {
        const {event} = this.props;
        return (
            <div style={{display: 'flex', flexShrink: 0}}>
                {event.state === 'new-action' ?
                    <StatusPipe>
                        <MdKeyboardCapslock size={iconSize} style={Object.assign({transform: 'rotate(180deg)'}, iconStyle)}/>
                    </StatusPipe>
                    : null}
                {event.state === 'end-action' ?
                    <StatusPipe noPipe>
                        <MdGetApp size={iconSize} style={Object.assign({}, iconStyle)}/>
                    </StatusPipe> : null}
                <div style={{margin: '8px 0 6px 6px'}}>
                    <p style={{margin: '0 0 6px 0'}}>
                        {event.state}&nbsp;
                        <small>@ {event.time}</small>
                    </p>
                </div>
            </div>
        );
    }
}

const PipelineEvent = withTranslation('projects')(themed(withProjects(withProjectActions(PipelineEventBase))));

const PayloadArtifact = withTranslation()((props) => {
    const {
        i18n,
        type, artifact, text
    } = props;

    return <div style={{padding: 4}}>
        <Link to={'/' + i18n.languages[0] + '/artifacts/' + type + '/' + artifact.id}
              style={{margin: '2px 0', display: 'block'}}
        >{text}: {artifact.id}</Link>
    </div>;
});

const PayloadDeployments = withTranslation()((props) => {
    const {deployments, i18n} = props;

    return <div style={{padding: 4}}>
        <p style={{margin: '0'}}>Started Deployments</p>
        {/*<p style={{margin: '2px 0'}}>{JSON.stringify(deployments)}</p>*/}
        <ul style={{margin: '2px 0 0 0'}}>{Object.keys(deployments).map(zone_id => (
            <li key={zone_id}>
                {/*<Link to={'/' + i18n.languages[0] + '/deployment/zone/' + zone_id}
                      style={{margin: '2px 0', display: 'block'}}
                >{zone_id}</Link>*/}
                {deployments[zone_id].deployment ?
                    <Link to={'/' + i18n.languages[0] + '/deployments/tracking/' + deployments[zone_id].deployment}
                          style={{display: 'block'}}
                    >Zone: {zone_id}</Link>
                    : null}
                {deployments[zone_id].servers ?
                    <PayloadDeploymentsServers servers={deployments[zone_id].servers} zone_id={zone_id}/> : null}
            </li>
        ))}</ul>
    </div>
});

const PayloadDeploymentsServers = withTranslation()((props) => {
    const {servers} = props;

    return servers ?
        <React.Fragment>
            <p style={{margin: '4px 0 4px 4px'}}>Satellite Details</p>
            <ul style={{paddingLeft: '25px'}}>
                {Object.keys(servers).map(server_id => (
                    <li key={server_id}>
                        {/*<Link to={'/' + i18n.languages[0] + '/deployment/server/' + zone_id + '/' + server_id}
                          style={{display: 'block'}}
                    >{server_id}</Link>*/}
                        <span style={{display: 'block'}}>{server_id}</span>
                        {servers[server_id].server && servers[server_id].server.endpoint ?
                            <span style={{display: 'block'}}>{servers[server_id].server.endpoint}</span> : null}
                        {/*{servers[server_id].deploy ?
                        <Link to={'/' + i18n.languages[0] + '/deploy-on-server/' + servers[server_id].deploy}
                              style={{display: 'block'}}
                        >Deployment Started: {servers[server_id].deploy}</Link> : null}*/}
                    </li>
                ))}
            </ul>
        </React.Fragment> : null
});

class PipelineEventStepPayloadBase extends React.Component {
    render() {
        const {payload} = this.props;

        return payload ?
            <div className={'bg-dark'} style={{fontFamily: 'monospace', flexShrink: 2, margin: '4px 0'}}>
                {payload.artifact_installable ?
                    <PayloadArtifact text={'created installable: id '} artifact={payload.artifact_installable} type={'installable'}/>
                    : null}
                {payload.artifact_deployable ? (
                    <PayloadArtifact text={'created deployable: id '} artifact={payload.artifact_deployable} type={'deployable'}/>
                ) : null}
                {payload.deployments ? (
                    <PayloadDeployments deployments={payload.deployments}/>
                ) : null}
            </div> : null;
    }
}

const PipelineEventStepPayload = withTranslation('projects')(themed(withProjects(withProjectActions(PipelineEventStepPayloadBase))));

class PipelineEventStepBase extends React.Component {
    state = {
        openMessage: false
    };

    render() {
        const {end, step_id, activeStep, finished, showMessage} = this.props;
        const _new = this.props.new;

        const payload_all = end ? JSON.parse(end.payload) : {};
        const payload = payload_all && payload_all[step_id] ? payload_all[step_id] : {};

        if(payload && payload.payload && payload.payload.artifact_installable) {
            //console.log('installable', payload.payload.artifact_installable);
        }
        if(payload && payload.payload && payload.payload.artifact_deployable) {
            //console.log('deployable', payload.payload.artifact_deployable);
        }

        return (
            <div style={{display: 'flex'}}>
                <StatusPipe minHeight>
                    {!end && finished ?
                        <MdCancel size={iconSize} className={payload.error ? 'error' : ''} style={Object.assign({}, iconStyle)}/> :
                        end ?
                            payload.error ?
                                <MdArrowDropDownCircle size={iconSize} className={payload.error ? 'error' : ''} style={Object.assign({}, iconStyle)}/> :
                                <MdCheckCircle size={iconSize} style={Object.assign({}, iconStyle)}/> :
                            _new ? <MdSync size={iconSize} className={payload.error ? 'error' : ''} style={Object.assign({}, iconStyle)}/> :
                                <MdHourglassEmpty size={iconSize} className={payload.error ? 'error' : ''} style={Object.assign({}, iconStyle)}/>}
                </StatusPipe>
                <div style={{margin: '0 0 18px 6px', flexGrow: 2, display: 'flex', flexDirection: 'column'}}>
                    <ButtonRaw onClick={() => this.props.toggleMessage(step_id)} style={{display: 'flex', width: '100%', flexShrink: 0}} active={_new.ID === activeStep || showMessage}>
                        <React.Fragment>
                                <span style={{padding: '2px 4px 2px 2px', margin: '4px 0'}}>
                                    {showMessage ?
                                        <MdSpeakerNotesOff size={'1.35em'} style={{display: 'block'}}/>
                                        : <MdSpeakerNotes size={'1.35em'} style={{display: 'block'}}/>}
                                </span>
                            <p style={{margin: '4px 6px 4px 0', flexShrink: 0, textAlign: 'left'}}>
                                <span style={{fontWeight: '700', letterSpacing: '0.03rem'}}>{step_id.substr(0, -1 !== step_id.indexOf(':') ? step_id.indexOf(':') + 1 : 0)}</span>
                                <span>{step_id.substr(-1 !== step_id.indexOf(':') ? step_id.indexOf(':') + 1 : 0)}</span>&nbsp;
                                {_new ?
                                    <small className={'text-light'}
                                           style={{display: 'block', paddingTop: 4}}
                                    >{_new.time} {end ? '@ ' + getDuration(_new.time, end.time).seconds + 's' : null}</small> : null}
                            </p>
                        </React.Fragment>
                    </ButtonRaw>

                    <PipelineEventStepPayload
                        payload={payload.payload}
                        message={payload.message}
                    />
                </div>
            </div>
        );
    }
}

class PipelineEventMessageBase extends React.Component {
    state = {
        openMessage: false
    };

    LoadSpan = (p) => <span {...p}/>;

    render() {
        const {end, step_id, finished} = this.props;
        const _new = this.props.new;

        const payload_all = end ? JSON.parse(end.payload) : {};
        const payload = payload_all && payload_all[step_id] ? payload_all[step_id] : {};

        return (
            <DropDown open={this.props.showMessage} className={'event-message step--' + step_id.replace(':', '__')}>
                {this.props.showMessage ?
                    <div style={{margin: '5px 6px 6px 6px'}}>
                        <p style={{margin: '8px 0', lineHeight: '1rem'}}>
                            {finished && !end ?
                                <MdCancel size={iconSize} style={Object.assign({verticalAlign: 'middle'}, iconStyle)}/> :
                                <MdArrowDropDownCircle size={iconSize} className={payload.error ? 'error' : ''} style={Object.assign({verticalAlign: 'middle'}, iconStyle)}/>}&nbsp;
                            {step_id}&nbsp;
                            {!end && _new ?
                                <Loading styleWrapper={{display: 'inline-block', margin: '0 0 0 4px'}} comp={this.LoadSpan}/> :
                                !finished && !_new ?
                                    <MdHourglassEmpty size={'1.2em'} style={Object.assign({verticalAlign: 'middle', marginTop: -7}, iconStyle)}/> :
                                    null}</p>
                        {payload.message && payload.message.length ?
                            payload.message.map((msg, i) => (
                                <div key={i}
                                     className={'bg-light' + (msg.substr(20, 4) === '] #!' ? ' error' : '')}
                                     style={{margin: '2px 0', marginRight: 0, padding: '2px 6px 2px 0', fontFamily: 'monospace', lineHeight: '1.25rem', display: 'flex'}}
                                >
                                    <p style={{width: '2.5rem', margin: '2px 0', textAlign: 'center', fontWeight: 'bold', flexShrink: 0}}>{i + 1}</p>
                                    <p style={{margin: '2px 0 4px 2px', wordBreak: 'break-word', overflowWrap: 'anywhere'}}>{msg}</p>
                                </div>
                            )) :
                            finished && !end ?
                                <div className={'bg-light'}
                                     style={{margin: '2px 0', marginRight: 0, padding: '2px 6px 2px 0', fontFamily: 'monospace', lineHeight: '1.25rem', display: 'flex'}}
                                >
                                    <p style={{margin: '2px 0 4px 2px', wordBreak: 'break-word', overflowWrap: 'anywhere'}}>got-canceled</p>
                                </div> :
                                payload.error ? null :
                                    <div className={'bg-light'}
                                         style={{margin: '2px 0', marginRight: 0, padding: '2px 6px 2px 0', fontFamily: 'monospace', lineHeight: '1.25rem', display: 'flex'}}
                                    >
                                        <p style={{margin: '2px 0 4px 2px', wordBreak: 'break-word', overflowWrap: 'anywhere'}}>{!payload.message ? 'no-payload' : 'empty-payload'}</p>
                                    </div>}
                        {payload.payload && payload.payload.deployments ?
                            <div>
                                <p className={'bg-light'} style={{margin: '12px 0 6px 0', padding: '6px 2px'}}><MdDns size={'2em'} style={{display: 'inline-block', verticalAlign: 'middle'}}/> Deployments</p>
                                {Object.keys(payload.payload.deployments).map(zone_id => (
                                    <div style={{margin: '12px 0 6px 0', padding: '6px 2px'}} className={'bg-light'} key={zone_id}>
                                        {payload.payload.deployments[zone_id].deployment ?
                                            /*<p>{payload.payload.deployments[zone_id].deployment}</p>*/
                                            <DeploymentDetailModul deployment={payload.payload.deployments[zone_id].deployment}/>
                                            : null}
                                        {payload.payload.deployments[zone_id].servers ?
                                            <PayloadDeploymentsServers servers={payload.payload.deployments[zone_id].servers} zone_id={zone_id}/> : null}
                                    </div>
                                ))}
                            </div> : null}
                    </div> : <div style={{minHeight: '80px', width: '100%'}}/>}
            </DropDown>
        );
    }
}

const PipelineEventStep = withTranslation('projects')(themed(withProjects(withProjectActions(PipelineEventStepBase))));
const PipelineEventMessage = withTranslation('projects')(themed(withProjects(withProjectActions(PipelineEventMessageBase))));

class ProjectActionDetailsBase extends React.Component {
    state = {
        loaded: false,
        reverse: false,
        finished: false,
        duration: {},
        event_log: {},
        showMessages: {},
        showMessagesAll: false,
    };
    intervalId = undefined;
    msg_container = undefined;

    componentDidMount() {
        this.props.loadProjectActionDetail(this.props.match.params.action);
        this.handleLoaded();
        this.intervalId = setInterval(() => {
            if(true === this.props.requestProjectActionsDetail[this.props.match.params.action] && !this.state.finished) {
                // only load again if loaded already
                // and not finished
                this.props.loadProjectActionDetail(this.props.match.params.action, true);
            }
        }, 2500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.requestProjectActionsDetail !== this.props.requestProjectActionsDetail ||
            prevProps.projectActionsDetail !== this.props.projectActionsDetail ||
            prevProps.match.params.action !== this.props.match.params.action) {
            this.props.loadProjectActionDetail(this.props.match.params.action);
            this.handleLoaded();
        }

        if(prevState.newShowing !== this.state.newShowing && this.state.newShowing) {
            const elem = this.msg_container.querySelector('.step--' + this.state.newShowing.replace(':', '__'));
            setTimeout(() => {
                if(this.msg_container) {
                    this.msg_container.scrollTop = elem.offsetTop;
                }
            }, 300);
        }

    }

    redirOnOnlyStep = () => {
        if(!this.props.match.params.step && this.state.reverse && this.state.reverse.length === 1 && this.state.reverse[0].state === 'new-pipeline-step') {
            this.props.loadProjectActionDetail(this.state.reverse[0].start);
            this.props.history.push('/' + this.props.i18n.languages[0] + '/projects/' + this.props.match.params.project + '/' + this.props.match.params.env + '/' + this.state.reverse[0].start + '/' + this.props.match.params.action);
        }
    };

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    toggleMessage = (id) => {
        const showMessages = {...this.state.showMessages};
        let newShowing = false;
        if(showMessages.hasOwnProperty(id)) {
            showMessages[id] = !showMessages[id];
        } else {
            showMessages[id] = true;
        }

        if(showMessages[id]) {
            newShowing = id;
        }
        this.setState({showMessages, newShowing});
    };

    toggleMessages = (showMessagesAll = true) => {
        const showMessages = {};
        if(this.state.event_log.pipeline.steps_all) {
            this.state.event_log.pipeline.steps_all.forEach((step_id) => {
                showMessages[step_id] = showMessagesAll;
            });
        }
        this.setState({showMessages, showMessagesAll});
    };

    handleLoaded() {
        const {
            projectActionsDetail, requestProjectActionsDetail,
            match
        } = this.props;
        const action = match.params.action;
        const loaded = true === requestProjectActionsDetail[action] || (projectActionsDetail && projectActionsDetail[action]);
        let reverse = false;
        const event_log = {
            new: {},
            end: {},
            pipeline: {
                steps_new: '',
                steps_end: [],
                steps_data: {}
            },
        };
        if(loaded) {
            reverse = [...projectActionsDetail[action]].reverse();

            [...projectActionsDetail[action]].forEach(event => {
                switch(event.state) {
                    case 'new-action':
                        event_log.new = event;
                        break;
                    case 'end-action':
                        event_log.end = event;
                        break;
                    case 'new-pipeline':
                        break;
                    case 'end-pipeline':
                        event_log.pipeline.steps_finished = JSON.parse(event.payload);
                        break;
                    case 'new-pipeline-step':
                        const step_id = JSON.parse(event.payload);
                        event_log.pipeline.steps_data[step_id] = {
                            new: event,
                        };
                        break;
                    case 'end-pipeline-step':
                        const steps_done = JSON.parse(event.payload);

                        Object.keys(steps_done).forEach((stepid => {
                            if(event_log.pipeline.steps_data.hasOwnProperty(stepid)) {
                                event_log.pipeline.steps_data[stepid].end = {
                                    event,
                                    done: steps_done[stepid],
                                };
                                if(steps_done[stepid].payload && steps_done[stepid].payload.all_steps) {
                                    event_log.pipeline.steps_all = steps_done[stepid].payload.all_steps;
                                }
                            } else {
                                console.error('a event_log pipline step has ended but was not started');
                                console.log(event);
                            }
                        }));
                        break;
                    default:
                        break;
                }
            });
        }
        const finished = loaded && reverse[0].state === 'end-action';
        let duration = {};
        if(finished) {
            duration = getDuration(projectActionsDetail[action][0].time, reverse[0].time);
        }

        this.redirOnOnlyStep();

        this.setState({
            loaded,
            reverse,
            finished,
            duration,
            event_log
        });
    }


    render() {
        const {t, match, projectActionsDetail} = this.props;
        const action = this.props.match.params.action;
        const {loaded, finished, duration, reverse, event_log} = this.state;

        const usable = loaded && event_log.new && projectActionsDetail && projectActionsDetail[action] && projectActionsDetail[action][0];

        return (
            <div className={'project-action-details'} style={{overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
                <h1>{t('title-action')}</h1>
                <h2 style={{margin: '4px 0 6px 0'}}><em>{usable ? projectActionsDetail[action][0].action + ' ' : null}</em> {t('action-on-env')} {match.params.project}:{match.params.env}
                </h2>
                <div className={'project-action-details-container'}>
                    <div className={'content-container'} style={{overflow: 'auto', textAlign: 'left', paddingTop: 0, flexShrink: 0, display: 'flex', flexDirection: 'column'}}>
                        {usable ? <p style={{margin: '2px 0', fontFamily: 'monospace'}}>{projectActionsDetail[action][0].time} {t('action-started-timezone')} {t('action-started')}</p> : null}
                        {finished ?
                            <div style={{marginBottom: 0, fontFamily: 'monospace'}}>
                                <p style={{margin: '2px 0'}}>{reverse[0].time} {t('action-started-timezone')} {t('action-finished')}</p>
                                <p style={{margin: '2px 0'}}>{duration.seconds > 160 ? (
                                    duration.minutes + 'min ' + (duration.seconds - (duration.minutes * 60)) + 's'
                                ) : duration.seconds + 's'} {t('action-duration')}</p>
                            </div>
                            : null}
                        <p style={{margin: '2px 0', textAlign: 'right'}}>
                            <ButtonRaw onClick={() => this.toggleMessages(!this.state.showMessagesAll)} style={{padding: 6}}>
                                All {this.state.showMessagesAll ?
                                <MdSpeakerNotesOff size={'1.2em'} style={{verticalAlign: 'middle', display: 'inline-block'}}/> :
                                <MdSpeakerNotes size={'1.2em'} style={{verticalAlign: 'middle', display: 'inline-block'}}/>}
                            </ButtonRaw>
                        </p>
                        {loaded && event_log.new ?
                            <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto', flexGrow: 2, paddingRight: 20}}>
                                <PipelineEvent event={event_log.new}/>
                                {event_log.pipeline.steps_all ? event_log.pipeline.steps_all.map((step_id) => (
                                    <PipelineEventStep key={step_id} step_id={step_id} new={event_log.pipeline.steps_data[step_id] ? event_log.pipeline.steps_data[step_id].new : false}
                                                       end={event_log.pipeline.steps_data[step_id] ? event_log.pipeline.steps_data[step_id].end ? event_log.pipeline.steps_data[step_id].end.event : false : false}
                                                       toggleMessage={this.toggleMessage}
                                                       showMessage={this.state.showMessages[step_id]}
                                                       finished={finished}
                                                       activeStep={match && match.params.step ? match.params.step : false}
                                    />
                                )) : null}
                                {finished ? <PipelineEvent event={event_log.end}/> : null}
                            </div>
                            : null}
                        {true !== this.props.requestProjectActionsDetail[action] ?
                            <LoadingRequest request={this.props.requestProjectActionsDetail[action]}/>
                            : null}
                    </div>
                    <div className={'message-container bg-dark'} style={{overflow: 'auto', position: 'relative', flexGrow: 2}} ref={(r) => this.msg_container = r}>
                        {loaded && event_log.pipeline.steps_all ?
                            event_log.pipeline.steps_all.map((step_id) => (
                                <PipelineEventMessage key={step_id}
                                                      step_id={step_id}
                                                      new={event_log.pipeline.steps_data[step_id] ? event_log.pipeline.steps_data[step_id].new : false}
                                                      end={event_log.pipeline.steps_data[step_id] ? event_log.pipeline.steps_data[step_id].end ? event_log.pipeline.steps_data[step_id].end.event : false : false}
                                                      toggleMessage={this.toggleMessage}
                                                      showMessage={this.state.showMessages[step_id]}
                                                      finished={finished}
                                />
                            ))
                            : null}
                        <div style={{height: '1px', marginBottom: '80vh'}}/>
                    </div>
                </div>
            </div>
        );
    }
}

const ProjectActionDetails = withTranslation('projects')(withRouter(themed(withProjects(withProjectActions(ProjectActionDetailsBase)))));

export {ProjectActionDetails};