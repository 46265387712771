import React from 'react';
import posed from 'react-pose';

const WrapperDrop = posed.div({
    hidden: {
        height: 0,
        padding: 0,
    },
    visible: {
        height: '100%',
        padding: 6,
    }
});

const DropDown = (props) => {
    return <WrapperDrop withParent={false} className={props.className} pose={props.open ? 'visible' : 'hidden'} style={Object.assign({overflow: 'hidden'}, props.style || {})}>
        {props.children}
    </WrapperDrop>
};

const createDropDown = (height = 'auto', padding = 6) => {
    const WrapperDropDyn = posed.div({
        hidden: {
            height: 0,
            padding: 0,
        },
        visible: {
            height: height,
            padding: padding,
        }
    });

    return (props) => {
        return <WrapperDropDyn withParent={false} className={props.className} pose={props.open ? 'visible' : 'hidden'} duration={250} style={Object.assign({overflow: 'hidden'}, props.style || {})}>
            {props.children}
        </WrapperDropDyn>
    };
};

const createDropRight = (width = 'auto', padding = 6) => {
    const WrapperDropDyn = posed.div({
        hidden: {
            width: 0,
            padding: 0,
        },
        visible: {
            width: width,
            padding: padding,
        }
    });

    return (props) => {
        return <WrapperDropDyn withParent={false} className={props.className} pose={props.open ? 'visible' : 'hidden'} duration={250} style={Object.assign({overflow: 'hidden'}, props.style || {})}>
            {props.children}
        </WrapperDropDyn>
    };
};

export {DropDown, createDropDown, createDropRight};