import React from 'react';
import {addHeader, apiRequest, POST, rmHeader, setEndpoint} from "../../lib/api";

const UserAuthStoreContext = React.createContext({});

class UserAuthStoreProviderBase extends React.Component {
    constructor(props) {
        super(props);

        const auth = window.localStorage.hasOwnProperty('automat_auth') ? JSON.parse(window.localStorage.getItem('automat_auth')) : {};

        if(auth.key && auth.server) {
            // set new server endpoint
            setEndpoint(auth.server);
            addHeader('AUTHORIZATION', 'Bearer ' + auth.key);
        }

        this.state = {
            authServer: auth.server || '',
            authKey: auth.key || '',
            doLogin: (server, key) => {
                // set new server endpoint
                setEndpoint(server);

                // make verify request
                return apiRequest('api/auth',
                    POST,
                    {
                        server: server,
                        key: key
                    })
                    .then(res => {
                        if(res.data.success) {
                            // when validated
                            // set auth data to local storage
                            window.localStorage.setItem('automat_auth', JSON.stringify({server, key}));
                            // set header for further requests
                            addHeader('AUTHORIZATION', 'Bearer ' + key);
                            // set values for usage in this not-reloaded session
                            this.setState({
                                authServer: server,
                                authKey: key
                            });
                        }

                        return res;
                    })
                    .catch(res => {
                        return Promise.reject(res);
                    });
            },
            doLogout: () => {
                window.localStorage.removeItem('automat_auth');
                rmHeader('AUTHORIZATION');
                setEndpoint('');
                this.setState({authServer: '', authKey: ''});
            }
        };
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <UserAuthStoreContext.Provider value={this.state}>
                {this.props.children}
            </UserAuthStoreContext.Provider>
        );
    }
}

const withUserAuth = (Component) => (
    (props) => {
        return (
            <UserAuthStoreContext.Consumer>
                {(context) => <Component {...context} {...props}/>}
            </UserAuthStoreContext.Consumer>
        )
    }
);

const UserAuthStoreProvider = UserAuthStoreProviderBase;

export {UserAuthStoreProvider, withUserAuth};