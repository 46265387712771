import React from 'react';
import {withRouter} from 'react-router-dom';
import {MdExpandMore, MdVisibility, MdVisibilityOff, MdDelete} from "react-icons/md";
import {withTranslation} from "../lib/i18n";
import {withUserAuth} from "./User/StoreAuth";
import {ButtonRaw} from "./Form";
import {LoadingRequest} from "./Loading";
import posed from 'react-pose';

const WrapperDrop = posed.div({
    hidden: {
        height: 0,
    },
    visible: {
        height: 'auto',
    }
});

class LoginBase extends React.Component {
    state = {
        inp_server: '',
        inp_key: '',
        inp_key_visible: false,
        show_servers_known: false,
        inp_servers_known: [],
        request: false
    };

    componentDidMount() {
        if(this.getServersKnown()) {
            this.setState({inp_servers_known: this.getServersKnown()});
        }
    }

    doLogin = (e) => {
        const {i18n, history, doLogin} = this.props;
        e.preventDefault();
        this.saveInpServer();

        this.setState({request: 'progress'});

        doLogin(this.state.inp_server, this.state.inp_key)
            .then(res => {
                if(res.data.success) {
                    history.push('/' + i18n.languages[0] + '/projects');
                    this.setState({request: true});
                    setTimeout(() => {
                        this.setState({request: false});
                    }, 400);
                } else {
                    this.setState({request: 'error'});
                }
            })
            .catch(res => {
                this.setState({request: 'error'});
            });
    };

    saveInpServer() {
        if(!this.state.inp_servers_known.includes(this.state.inp_server)) {
            let inp_servers_known = [...this.state.inp_servers_known];
            inp_servers_known.push(this.state.inp_server);
            window.localStorage.setItem('automat_known_server', JSON.stringify(inp_servers_known));
            this.setState({inp_servers_known});
        }
    }

    selectServerKnown = (i) => {
        this.setState({inp_server: this.state.inp_servers_known[i]});
    };

    deleteServerKnown = (i) => {
        let inp_servers_known = [...this.state.inp_servers_known];
        inp_servers_known.splice(i, 1);
        this.setState({inp_servers_known});
    };

    getServersKnown() {
        if(window.localStorage.hasOwnProperty('automat_known_server')) {
            return JSON.parse(window.localStorage.getItem('automat_known_server'));
        }
        return false;
    }

    render() {
        const {t} = this.props;

        return <div style={{margin: '0 auto', padding: '100px 0 200px 0'}}>
            <form onSubmit={this.doLogin}>
                <div>
                    <label htmlFor={'login-server'} style={{display: 'block', padding: '0 0 6px 0'}}>{t('login.server')}</label>
                    <div style={{position: 'relative'}}>
                        <input id={'login-server'} style={{display: 'block'}} type={'text'} value={this.state.inp_server}
                            /*onKeyDown={e => {
                                if(e.keyCode === 40) {
                                    e.target.autocomplete = "off";
                                    this.setState({show_servers_known: true})
                                }
                            }}*/
                               onChange={e => this.setState({inp_server: e.target.value})}/>
                        <ButtonRaw style={{position: 'absolute', right: 0, transform: 'translate(100%, -50%)', top: '50%', padding: 4}} type='button'
                                   onClick={() => this.setState({show_servers_known: !this.state.show_servers_known})}>
                            <MdExpandMore size={'1.35em'} style={{
                                display: 'block',
                                transform: (this.state.show_servers_known ? ' rotate(-180deg)' : ''),
                                transition: '0.4s linear transform'
                            }}/>
                        </ButtonRaw>
                    </div>
                    <WrapperDrop withParent={false} pose={this.state.show_servers_known ? 'visible' : 'hidden'} className={'bg-light'} style={{overflow: 'hidden'}}>
                        {this.state.inp_servers_known.map((elem, i) => (
                            <div key={elem} style={{display: 'flex'}}>
                                <ButtonRaw style={{flexGrow: 3, textAlign: 'left', padding: '2px 6px'}}
                                           onClick={() => this.selectServerKnown(i)}
                                           type='button'>{elem}</ButtonRaw>
                                <ButtonRaw onClick={() => this.deleteServerKnown(i)}
                                           style={{marginLeft: 'auto', flexShrink: 3, padding: 4}}
                                           type='button'><MdDelete size={'1.25em'}/></ButtonRaw>
                            </div>
                        ))}
                    </WrapperDrop>
                </div>
                <div>
                    <label htmlFor={'login-key'} style={{display: 'block', padding: '9px 0 6px 0'}}>{t('login.key')}</label>
                    <div style={{position: 'relative'}}>
                        <input id={'login-key'} style={{display: 'block'}} type={this.state.inp_key_visible ? 'text' : 'password'} value={this.state.inp_key} onChange={e => this.setState({inp_key: e.target.value})}/>
                        <ButtonRaw style={{position: 'absolute', right: 0, transform: 'translate(100%, -50%', top: '50%', padding: 4}} onClick={() => this.setState({inp_key_visible: !this.state.inp_key_visible})} type='button'>
                            {this.state.inp_key_visible ?
                                <MdVisibilityOff size={'1.35em'} style={{display: 'block'}}/> :
                                <MdVisibility size={'1.35em'} style={{display: 'block'}}/>}
                        </ButtonRaw>
                    </div>
                </div>
                <div style={{display: 'block', padding: '9px 0 6px 0'}}>
                    {false === this.state.request || 'error' === this.state.request ?
                        <button style={{cursor: 'pointer'}}>{t('login.submit')}</button> : null}
                    {false !== this.state.request ?
                        <LoadingRequest request={this.state.request}
                                        text={t('login.' + (
                                            'progress' === this.state.request ?
                                                'submitting' :
                                                true === this.state.request ?
                                                    'success' :
                                                    'error' === this.state.request ?
                                                        'error-wrong-combi' :
                                                        ''
                                        ))}/> : null
                    }
                </div>
            </form>
        </div>
    }
}

const Login = withRouter(withTranslation('common')(withUserAuth(LoginBase)));
export {Login};