// GET
const GET = 'GET';

// POST
const POST = 'POST';
const PUT = 'PUT';

// DELETE
const DELETE = 'DELETE';

let HOST_ENDPOINT = '';

const DEFAULT_HEADER = {};

const setEndpoint = (host) => {
    // todo: sanitize host
    HOST_ENDPOINT = host;
};

const addHeader = (name, url) => {
    DEFAULT_HEADER[name] = url;
};

const getHeaders = () => {
    return DEFAULT_HEADER;
};

const rmHeader = (name) => {
    if(DEFAULT_HEADER[name]) {
        delete DEFAULT_HEADER[name];
    }
};

function apiRequest(endpoint, method, data, headers = {}) {
    headers = Object.assign(DEFAULT_HEADER, headers);
    headers['Content-Type'] = 'application/json';
    //'Content-Type': 'application/x-www-form-urlencoded',
    return fetch(HOST_ENDPOINT + '/' + endpoint, {
        method: method,
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers,
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
        .then(response => {
            return response.text()
                .then((text) => {
                    return {
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                        body: text
                    };
                })
                .then((response) => {
                    let json = JSON.parse('{}');
                    try {
                        if('string' === typeof response.body && 0 < response.body.length) {
                            json = JSON.parse(response.body);
                        } else {
                            //console.error(response.body);
                            throw new Error('Api: response body is not JSON for request ' + endpoint);
                        }
                    } catch(e) {
                        // not json, will use empty json object as result
                        console.warn(e.message);
                    }

                    return {
                        status: response.status,
                        headers: response.headers,
                        json
                    };
                })
                .then((result) => {
                    let res = {};
                    res.status = result.status;
                    res.headers = result.headers;
                    res.data = result.json;

                    if(res.status < 200 || res.status >= 300) {
                        return Promise.reject(res);
                    }

                    return res;
                });
        })
}

export {apiRequest};

export {
    GET,
    PUT,
    POST,
    DELETE,
    setEndpoint,
    addHeader,
    getHeaders,
    rmHeader,
};