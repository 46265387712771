import React, {Suspense} from 'react';
import {
    Switch,
    BrowserRouter,
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom';
import {Header} from "./layout/Header";
import {Sidebar} from "./layout/Sidebar";
import './App.scss';
import 'normalize.css/normalize.css';
import {Login} from "./component/Login";
import {I18nProvider} from "./lib/i18n";
import {themed, ThemeProvider} from "./lib/theme";
import {UserAuthStoreProvider, withUserAuth} from "./component/User/StoreAuth";
import {Loading} from "./component/Loading";
import {ProjectsList} from "./component/Projects/ProjectsList";
import {ProjectActionDetails} from "./component/Projects/ProjectActionDetails";
import {ProjectsStoreProvider} from "./component/Projects/StoreProjects";
import {DeploymentsList} from "./component/Deployments/DeploymentsList";
import {ProjectActionsStoreProvider} from "./component/Projects/StoreProjectActions";
import {StoreDeployments} from "./component/Deployments/StoreDeployments";
import {Settings} from "./component/Settings";
import {KeyboardShortcutsProvider, withKeyboardShortcuts} from "./component/KeyboardShortcuts";
import {withTranslation} from "react-i18next";
import {DeploymentDetail} from "./component/Deployments/DeploymentDetail";

const localeConfig = {
    allLanguages: [
        "en",
        "de"
    ],
    defaultLanguage: "en",
    expirationTime: process.env.NODE_ENV === 'production' ? 2 * 24 * 60 * 60 * 1000 : 100,
    debug: false
};

const LoginRedir = withTranslation('common')(withRouter(withUserAuth((props) => {
    if(props.authServer && props.authKey) {
        return <props.component {...props.comp_props}/>;
    } else {
        props.history.push('/' + props.i18n.languages[0] + '/auth');

        return <p>redirect to login</p>;
    }
})));

const needsLogin = (Component) => (comp_props) => <LoginRedir component={Component} comp_props={comp_props}/>;

const ControllerProjectsList = needsLogin(ProjectsList);
const ControllerDeploymentsList = needsLogin(DeploymentsList);
const ControllerDeploymentDetail = needsLogin(DeploymentDetail);
const ControllerProjectActionDetails = needsLogin(ProjectActionDetails);
const ControllerSettings = needsLogin(Settings);

class BaseContent extends React.Component {
    state = {
        sidebarOpen: false
    };

    componentDidMount() {
        if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 920) {
            // eslint-disable-next-line
            this.state.sidebarOpen = true;
            this.props.setShort('menu', true);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {shortWasPressed, isShortActive, shortcutsInUse} = this.props;
        if(shortWasPressed('menu') &&
            prevProps.shortcutsInUse !== shortcutsInUse) {
            this.setState({sidebarOpen: isShortActive('menu')});
        }
    }

    toggleSidebar = () => {
        this.setState({sidebarOpen: !this.state.sidebarOpen}, () => {
            this.props.setShort('menu', this.state.sidebarOpen);
        });
    };

    render() {

        return (
            <React.Fragment>
                <Header openSidebar={this.toggleSidebar}
                        sidebarOpen={this.state.sidebarOpen}
                />

                <div className={'center' + (this.state.sidebarOpen ? ' open-sidebar' : '')}>
                    <Switch>
                        <Route exact path="/:lng" render={(props) => (
                            <Redirect to={'/' + props.match.params.lng + '/auth'}/>
                        )}/>

                        <Route path="/:lng" render={(props) => (
                            <React.Fragment>
                                <div className={'content' + (this.state.sidebarOpen ? ' open-sidebar' : '')}>
                                    <Suspense fallback={<Loading center/>}>
                                        <Route path={'/:lng/auth'} component={Login}/>

                                        <Route path={'/:lng/projects'} exact component={ControllerProjectsList}/>
                                        <Route path={'/:lng/projects/:project/:env/:action/:step?'} exact component={ControllerProjectActionDetails}/>

                                        {/*
                                        <Link to={'/' + i18n.languages[0] + '/artifacts/' + type + '/' + artifact.id}
                                    */}
                                        <Route path={'/:lng/artifacts/:type?/:id?'} render={props => (
                                            <div style={{width: '100%'}}>
                                                <h1>Artifacts</h1>
                                                {props.match.params.type ?
                                                    <h2>{props.match.params.type} {props.match.params.id}</h2> : null}
                                            </div>
                                        )}/>

                                        {/*
                                        <Link to={'/' + i18n.languages[0] + '/deployment/zone/' + zone_id}
                                        <Link to={'/' + i18n.languages[0] + '/deployment/server/' + zone_id + '/' + server_id}
                                        <Link to={'/' + i18n.languages[0] + '/deploy-on-zone/' + deployments[zone_id].deployment}
                                        <Link to={'/' + i18n.languages[0] + '/deploy-on-server/' + servers[server_id].deploy}
                                    */}
                                        <Switch>
                                            <Route path={'/:lng/deployments/tracking/:deployment'} exact component={ControllerDeploymentDetail}/>
                                            <Route path={'/:lng/deployments/:project?/:env?'} exact component={ControllerDeploymentsList}/>
                                        </Switch>

                                        <Route path={'/:lng/settings'} component={ControllerSettings}/>

                                        {this.props.children}
                                    </Suspense>
                                </div>

                                <Sidebar open={this.state.sidebarOpen} openSidebar={this.toggleSidebar} match={props.match} history={props.history}/>
                            </React.Fragment>
                        )}/>
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

const Content = themed(withKeyboardShortcuts(BaseContent));

const Providers = withUserAuth((props) => {
    if(props.authServer && props.authKey) {
        return <ProjectsStoreProvider>
            <ProjectActionsStoreProvider>
                <StoreDeployments>
                    {props.children}
                </StoreDeployments>
            </ProjectActionsStoreProvider>
        </ProjectsStoreProvider>;
    } else {
        return props.children;
    }
});

const ThemedApp = themed((props) =>
    <div className={"App " + props.theme}>
        {props.children}
    </div>);

class App extends React.Component {
    render() {
        return (
            <ThemeProvider>
                <BrowserRouter basename="/">
                    <I18nProvider config={localeConfig} defaultLanguage={localeConfig.defaultLanguage}>
                        <KeyboardShortcutsProvider>
                            <UserAuthStoreProvider>
                                <Providers>
                                    <ThemedApp>
                                        <Suspense fallback={<Loading center/>}>
                                            <Content/>
                                        </Suspense>
                                    </ThemedApp>
                                </Providers>
                            </UserAuthStoreProvider>
                        </KeyboardShortcutsProvider>
                    </I18nProvider>
                </BrowserRouter>
            </ThemeProvider>
        );
    }
}

export default App;
